import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import "assets/demo/demo.css";
import { useLocation } from "react-router-dom";
import { getSessionStorage } from '../utils';

// reactstrap components
import {
  Button,
  Container
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ThankYouPageHeader from "components/Headers/ThankYouPageHeader";
import DarkFooter from "components/Footers/DarkFooter";
const apiUrl = process.env.REACT_APP_API_BACKEND_URL;

function ThankYouPage() {
  const [showConfetti, setShowConfetti] = useState(true);
  const [fadeConfetti, setFadeConfetti] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Start fading confetti after 2 seconds
    const fadeTimer = setTimeout(() => {
      setFadeConfetti(true);
    }, 2000);

    // Hide confetti after 2.5 seconds to allow for fading
    const hideTimer = setTimeout(() => {
      setShowConfetti(false);
    }, 2500);

    // Extract session ID from the URL
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const paymentEmail = getSessionStorage('email');
  

    fetch(`${apiUrl}database/payment-complete`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sessionId,
        paymentEmail,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data.message);
        sessionStorage.setItem(sessionId, 'processed');
      })
      .catch(error => {
        console.error("Error:", error);
      });
    
    

    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
      clearTimeout(fadeTimer);
      clearTimeout(hideTimer);
    };
  }, [location.search]);


  return (
    <>
      <ExamplesNavbar />
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={500}
          className={`confetti ${fadeConfetti ? 'fade-out' : ''}`}
        />
      )}
      <div className="wrapper">
        <ThankYouPageHeader />
        <div className="section">
          <Container>
            <div className="button-container">
              <Button className="btn-round" color="info" size="lg">
                Siguenos! <i className="fab fa-instagram"></i>
              </Button>
            </div>
            <h3 className="title">Recomendaciones:</h3>
            <h5 >
              Trae crema solar, gorra, gafas de sol, ropa cómoda y agua. Nosotros te daremos dos bolsas de hielo, en el barco habra 
              una nevera para que puedas guardar todos tus refrescos. ¡Disfruta de tu día en el mar!
            </h5>
            <p className="p-sidenote">
              *Se cogera un deposito de 500€ que sera devuelto una vez se haya acabado el alquiler.
            </p>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default ThankYouPage;
