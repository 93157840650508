
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import LoginPage from "views/examples/LoginPage.js";
import BarcosPage from "views/examples/BarcosPage.js";
import CalendarioPage from "views/examples/CalendarioPage.js";
import DetailsPage from "views/examples/DetailsPage.js";
import ThankYouPage from "views/examples/ThankYouPage";
import ErrorPage from "views/examples/ErrorPage";


const root = ReactDOM.createRoot(document.getElementById("root"));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_NONPROD_KEY);
if (!process.env.REACT_APP_STRIPE_NONPROD_KEY) {
  console.error('Stripe API key is missing. Please set it in the .env file.');
}

root.render(
  <Elements stripe={stripePromise}>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/barcos" element={<BarcosPage />} />
      <Route path="/thankYou" element={<ThankYouPage />} />
      <Route path="/barcos" element={<LoginPage />} />
      <Route path="/calendar" element={<CalendarioPage />} />
      <Route path="/details" element={<DetailsPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </BrowserRouter>
  </Elements>
);
