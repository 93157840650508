import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";

// core components

function ThankYouPageHeader() {
  const [sessionValueName, setSessionValue] = useState("");
  let pageHeader = React.createRef();

  useEffect(() => {
    const storedValue = sessionStorage.getItem('name');
    if (storedValue) {
      setSessionValue(storedValue);
    }


  }, []);

  return (
    <>
      <div
        className="page-header clear-filter page-header-small"
        filter-color="blue"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/water-background.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="photo-container">
            <img alt="..." src={require("assets/img/success1.jpg")}></img>
          </div>
            <h2 className="title">Reserva Confirmada!</h2>
          <h3 > 
            Muchas Gracias {sessionValueName} por reservar con nosotros. En breve recibiras un email con mas detalles..
          </h3>
        </Container>
      </div>
    </>
  );
}

export default ThankYouPageHeader;
