import React from "react";
import Calendar from "react-calendar";
import { useState } from "react";
import "assets/demo/demo.css";
import es from 'date-fns/locale/es';
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import { setSessionStorage } from '../utils';


// reactstrap components
import {
  Button,
} from "reactstrap";

// core components
import DarkFooter from "components/Footers/DarkFooter";
import BlankPageHeader from "components/Headers/BlankPageHeader";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar";
import WAFooter from "components/Footers/WAFooter.js";
const apiUrl = process.env.REACT_APP_API_BACKEND_URL;
function MyCalendar() {

  // Define state to manage the unavailable dates
  const [unavailableDates, setUnavailableDates] = useState([]);
    // Define state to manage the selected date
  const [date, setDate] = useState(new Date());
  // Define a navigate function to redirect to the details page
  const navigate = useNavigate();

  const onChange = async (newDate) => {
    setDate(newDate);
    await setSessionStorage('date', newDate);
  };
  const [loading, setLoading] = useState(true); // Add loading state
  const handleReservarClick = () => {
    navigate('/details', { state: { date } });
  };
  React.useEffect(() => {

    const fetchEvents = async () => {
      setLoading(true); // Set loading state to true before fetching
      try {
        const response = await fetch(`${apiUrl}calendar/all-events`);
        const data = await response.json();
        const dates = data.events.map(event => event.start.date);
        setUnavailableDates(dates);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false); // Set loading state to false after fetching
      }
    };

    fetchEvents();
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);


  // Disable unavailable dates
  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      return unavailableDates.some(unavailableDate => {
        const d = new Date(`${unavailableDate}T12:00:00`);
        return date.getFullYear() === d.getFullYear() &&
               date.getMonth() === d.getMonth() &&
               date.getDate() === d.getDate();
      });
    }
    return false;
  };
  



   return (
    <>
      <ExamplesNavbar />
      <BlankPageHeader />
      <div className="page-container">
        {loading ? ( // Conditionally render the loade
          <div className="loader_refresh"></div>
        ) : (
          <div className="calendar-container">
            <Calendar 
              onChange={onChange} 
              value={date}
              locale="es"
              tileDisabled={tileDisabled}
            />
            <div className="price-container">
              <p>
                Fecha Seleccionada: {" "}
                {Array.isArray(date) 
                  ? `${format(date[0], 'PP', { locale: es })} - ${format(date[1], 'PP', { locale: es })}`
                  : format(date, 'PP', { locale: es })}
              </p>
              <Button className="btn-round" color="info" onClick={handleReservarClick}  transition={{ timeout: 150 }}>
                <p>Reservar</p>
              </Button>
              
            </div>
            <p>👉La reserva comienza a las 10:00 y finaliza a las 17:00.</p>
            <p>👉El precio es de 300€, con un depósito de 50€ para confirmar la reserva que se hara en el siguiente paso.</p>
            <p>👉La embarcación admite un máximo de 8 personas y es imprescindible contar con el titulin para poder manejarla.</p>
            <p>Nota: Si tienes alguna consulta sobre un rango de fechas específico u otra duda, contáctanos por WhatsApp.</p>
            <div>
              <br></br>
            </div>
          </div>
        )}
      </div>
      <WAFooter/>
      <DarkFooter />
    </>
  );
}

export default MyCalendar;
