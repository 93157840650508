import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  return (
    <>
      {/* Background click handler for mobile collapse */}
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      {/* Navbar Component */}
      <Navbar className="fixed-top navbar-solid" expand="lg">
        <Container className="d-flex justify-content-between align-items-center">
          {/* Navbar Brand */}
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <img
              src={require("assets/img/rwlogo.png")}
              className="nav-logo2"
              alt="RW Logo"
            />
            <img
              src={require("assets/img/ridingWaves.png")}
              className="nav-logo"
              alt="Riding Waves"
            />
          </Link>

          {/* Navbar Toggler for Mobile */}
          <button
            className="navbar-toggler"
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setCollapseOpen(!collapseOpen);
            }}
            aria-expanded={collapseOpen}
            type="button"
          >
            <span className="navbar-toggler-bar top-bar"></span>
            <span className="navbar-toggler-bar middle-bar"></span>
            <span className="navbar-toggler-bar bottom-bar"></span>
          </button>

          {/* Navbar Collapse */}
          <Collapse className="justify-content-end" isOpen={collapseOpen} navbar>
            <Nav navbar>
              {/* Barcos Page */}
              <NavItem>
                <Link to="/barcos" className="nav-link">
                  <i className="now-ui-icons objects_spaceship"></i>
                  <span className="nav-text">Barcos</span>
                </Link>
              </NavItem>

              {/* Reservar Button */}
              <NavItem>
                <Link to="/calendar">
                  <Button className="nav-link btn-round" color="info">
                    <p>Reservar</p>
                  </Button>
                </Link>
              </NavItem>

              {/* Instagram Link */}
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
