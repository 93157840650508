import React from "react";
import { Helmet } from "react-helmet";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter";
import WAFooter from "components/Footers/WAFooter.js";

function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <Helmet>

        <title>Alquiler de Barcos en Murcia | Disfruta del Mar Menor</title>
        <meta
          name="description"
          content="Alquila un barco en el Mar Menor, Murcia, y disfruta de un día inolvidable navegando con tus familiares y amigos. Reserva ahora y vive una experiencia única."
        />
        <meta
          name="keywords"
          content="alquiler de barcos Murcia, alquiler barco Mar Menor, paseo en barco Murcia, navegar en Murcia, barcos sin titulación Murcia"
        />
        <meta name="author" content="RidingWaves" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.riding-waves.com/" />

        <meta property="og:title" content="Alquiler de Barcos en Murcia | Disfruta del Mar Menor" />
        <meta
          property="og:description"
          content="Descubre el Mar Menor alquilando un barco en Murcia. Relájate y navega con amigos o familiares. Reserva tu barco hoy mismo."
        />
        <meta property="og:url" content="https://www.riding-waves.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          <section className="section">
            <div className="container">
              <h1>Alquiler de barcos en Murcia - Mar Menor</h1>
              <p>
                Bienvenido a nuestro servicio de alquiler de barcos en <strong>Murcia, en el Mar Menor</strong>. 
                Disfruta de una experiencia única navegando por las aguas tranquilas del Mar Menor, 
                perfecto para familias, amigos y escapadas románticas.
              </p>
              
              <h2>¿Por qué alquilar con nosotros?</h2>
              <ul>
                <li>🚤 Barcos nuevo y bien equipado</li>
                <li>🌊 Navegación en el <strong>Mar Menor, Murcia</strong></li>
                <li>📍 Salida desde un puerto seguro y accesible en <strong>Puerto de Lo Pagan, Murcia</strong></li>
                <li>💰 Precio competitivo</li>
                <li>⏳ Alquiler por medio día o días completos</li>
                <li>🎉 Ideal para celebraciones y escapadas</li>
              </ul>

              <h2>📍 Ubicación</h2>
              <p>
                Nos encontramos en <strong>Puerto de Lo Pagan(Mar Menor), Murcia</strong>, una de las mejores zonas para la navegación recreativa en España.
              </p>

              <h2>📞 Contacto</h2>
              <p>
                Para más información, envíanos un WhatsApp al <a href="tel:+34641561101">+34 641 561 101 </a> 
                o un correo a <a href="mailto:reservas@riding-waves.com">reservas@riding-waves.com</a>.
              </p>
            </div>
          </section>
        </div>
        <WAFooter />
        <DefaultFooter />
      </div>
    </>
  );
}

export default Index;
