import React from 'react';
import "assets/demo/demo.css";

const WAFooter = () => {
  const phoneNumber = '+34641561101'; // Replace with your WhatsApp number (e.g., '1234567890')
  const message = 'Hola! Estoy interesad@ en tu barco.';

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/a/a7/2062095_application_chat_communication_logo_whatsapp_icon.svg" alt="WhatsApp" />
    </div>
  );
};

export default WAFooter;
