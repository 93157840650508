/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";


// reactstrap components
import { Container } from "reactstrap";
// core components

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header clear-filter" filter-color="white">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/boat.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand">
            <h2 className="h1-seo">Surca las Olas del Mar</h2>
            <h3 className="h2-seo"> Estamos en Club Nautico Lo Pagan, Murcia</h3>
            <h4 className="h2-seo"> 37º 49´ N / 00º 47´ W</h4>
            <Link to="/calendar">
                <Button
                  className="btn-round"
                  color="info"
                >
                  Ver Disponibilidad
                </Button>
            </Link>
            
          </div>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
