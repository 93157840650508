import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "assets/demo/demo.css";
import { useLocation } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ErrorPageHeader from "components/Headers/ErrorPageHeader";
import DarkFooter from "components/Footers/DarkFooter";


function ErrorPage() {
  const location = useLocation();

  useEffect(() => {
  

    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [location.search]);


  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <ErrorPageHeader />
        <div className="section">
          <Container>
            <div className="button-container">
              <Link to="/calendar">
                <Button
                  className="btn-round" color="info" size="lg"
                >
                  Ver Disponibilidad
                </Button>
            </Link>
            </div>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default ErrorPage;
