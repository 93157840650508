export const setSessionStorage = (name, value) => {
  if (typeof window !== "undefined") {
      const stringValue = typeof value === "string" ? value : JSON.stringify(value);
      window.sessionStorage.setItem(name, stringValue);
  }
};

export const getSessionStorage = (name) => {
  if (typeof window !== "undefined") {
      const value = window.sessionStorage.getItem(name);
      try {
          return JSON.parse(value);
      } catch (error) {
          return value;
      }
  }
  return null;
};