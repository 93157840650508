import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter";

function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Quienes somos?</h2>
                <h4 className="title"> 
                RidingWaves es libertad sobre el agua. 🌊
                </h4>
                <h5 style={{ color: 'black' }}>
                Únete a nuestro club y navega sin complicaciones: olvídate de los dolores de cabeza de tener barco propio. 
                Disfruta de nuestra lancha MARETTI en Murcia - Mar Menor cuando quieras, sin ataduras.
                Somos más que un club, somos una comunidad de amantes del mar que apuesta por una navegación sostenible y eco-friendly. ¿Te unes a la familia RidingWaves? 🌊
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/sistema_musica.jpg") + ")"
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "Una experiencia inolvidable en el Mar Menor con amigos! 
                      Disfrutamos de un día perfecto navegando en barco, 
                      rodeados de aguas cristalinas y paisajes impresionantes. 
                       <br></br>
                      <br></br>
                      <small>-Pablo</small>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/solarium_popa.jpeg") + ")"
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/water-background.jpg") + ")"
                    }}
                  ></div>
                  <h3 style={{ marginTop: '20px' }}>
                    Dejate llevar por las olas y ven a disfrutar de nuestra lancha MARETI 585 OPEN
                  </h3>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/mesa.jpeg") + ")"
                    }}
                  ></div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
