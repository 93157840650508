import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Container,
  Col,
} from "reactstrap";
import DarkFooter from "components/Footers/DarkFooter";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { setSessionStorage } from "views/utils";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_NONPROD_KEY);
const apiUrl = process.env.REACT_APP_API_BACKEND_URL;

function DetailsPage() {
  const [formCompleted, setFormCompleted] = useState(false);
  const [formValues, setFormValues] = useState({
    nombre: "",
    apellidos: "",
    email: "",
    telefono: "",
    dni: "",
  });
  const [bookingId, setBookingId] = useState(null);

  const location = useLocation();
  const { date } = location.state || {};
  const formattedDate = date ? format(new Date(date), "PP") : "No date selected";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // Dynamically check if the form is complete
  useEffect(() => {
    const { nombre, apellidos, email, telefono, dni } = formValues;
    setFormCompleted(nombre && apellidos && email && telefono && dni);
  }, [formValues]);

  // Save name and email to sessionStorage
  useEffect(() => {
    if (formValues.nombre && formValues.email) {
      setSessionStorage("name", formValues.nombre);
      setSessionStorage("email", formValues.email);
    }
  }, [formValues.nombre, formValues.email]);

  // 🔹 Save booking when DNI input loses focus
  const handleDniBlur = async () => {
    const { nombre, apellidos, email, telefono, dni } = formValues;

    if (nombre && apellidos && email && telefono && dni && !bookingId) {
      const id = await handleSaveBooking();
      if (id) {
        setBookingId(id);
      }
    }
  };

  const handleSaveBooking = async () => {
    try {
      const { nombre, apellidos, email, telefono, dni } = formValues;
      const response = await fetch(`${apiUrl}database/save-rental`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          first_name: nombre,
          last_name: apellidos,
          customer_id: dni,
          phone: telefono,
          rental_date: formattedDate,
          customer_email: email,
        }),
      });
      const result = await response.json();
      if (result.error) {
        alert(result.error);
        return null;
      } else {
        return result.id;
      }
    } catch (error) {
      alert("Error creating booking: " + error.message);
      return null;
    }
  };

  const handlePayment = async () => {
    if (!bookingId) {
      alert("Booking not saved yet. Please fill out the form and wait.");
      return;
    }
    try {
      const stripe = await stripePromise;
      const response = await fetch(`${apiUrl}stripe/create-checkout-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          date: formattedDate,
          rental_id: bookingId, 
        }),
      });
      const session = await response.json();
      if (session.error) {
        alert(session.error);
        return;
      }
      const result = await stripe.redirectToCheckout({ sessionId: session.id });
      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    document.body.classList.add("landing-page", "sidebar-collapse");
    return () => {
      document.body.classList.remove("landing-page", "sidebar-collapse");
    };
  }, []);

  return (
    <>
      <ExamplesNavbar />
      <div className="page-header clear-filter">
        <div className="content">
          <h2 className="h2-detalles text-center">
            Información Reserva: {formattedDate}
          </h2>
          <Container>
            <Col className="mx-auto" md="6" lg="5">
              <Card className="card-login">
                <Form className="form">
                  <CardBody>
                    {["nombre", "apellidos", "email", "telefono", "dni"].map(
                      (field, index) => (
                        <InputGroup key={index} className="input-group-lg my-3">
                          <InputGroupText>
                            <i
                              className={`now-ui-icons ${
                                field === "email"
                                  ? "ui-1_email-85"
                                  : field === "telefono"
                                  ? "tech_mobile"
                                  : "users_circle-08"
                              }`}
                            ></i>
                          </InputGroupText>
                          <Input
                            placeholder={
                              field.charAt(0).toUpperCase() + field.slice(1)
                            }
                            type="text"
                            name={field}
                            value={formValues[field]}
                            onChange={handleInputChange}
                            onBlur={field === "dni" ? handleDniBlur : undefined} // 
                          />
                        </InputGroup>
                      )
                    )}
                  </CardBody>
                  <CardFooter className="text-center">
                    {!formCompleted ? (
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        size="lg"
                        disabled
                      >
                        Complete todos los campos
                      </Button>
                    ) : (
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="success"
                          size="lg"
                          onClick={handlePayment}
                        >
                          Completar Reserva
                        </Button>
                        <p className="p-sidenote">
                          Nota: Será redirigido a la pasarela de pago de Stripe
                          para completar la reserva de 50€.
                        </p>
                      </div>
                    )}
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default DetailsPage;
