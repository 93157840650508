import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";

// core components

function ErrorPageHeader() {
  const [setSessionValue] = useState("");
  let pageHeader = React.createRef();

  useEffect(() => {
    const storedValue = sessionStorage.getItem('nombre');
    if (storedValue) {
      setSessionValue(storedValue);
    }


  }, []);

  return (
    <>
      <div
        className="page-header clear-filter page-header-small"
        filter-color="blue"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/water-background.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="photo-container">
            <img alt="..." src={require("assets/img/error-icon.jpg")}></img>
          </div>
            <h2 className="title">Reserva Incompleta</h2>
          <h3 > 
           Tu reserva no ha podido ser completada. Por favor, intenta de nuevo.
          </h3>
        </Container>
      </div>
    </>
  );
}

export default ErrorPageHeader;
